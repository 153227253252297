import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Important Deadlines</h3>
          <p>
            <ul>
              <li>January 2, 2018 - Bid Procedures Objection Deadline</li>
              <li>January 3, 2018 - Bid Procedures Hearing Date</li>
              <li>February 2, 2018 - New Bidder Due Diligence Deadline</li>
              <li>February 3, 2018 - New Bidder Initial Bid Deadline </li>
              <li>February 15, 2018 - Auction Date </li>
              <li>February 15, 2018 - Deadline to Object to Sale </li>
              <li>February 16, 2018 - Hearing to Confirm Sale</li>
            </ul>
          </p>

          <p>
            For an overview of current bids on the portfolio, click here:{" "}
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Summary-of-Manor-House-Bids-v5.pdf"
            >
              Summary of Manor House Bids.
            </Link>
             {" "}
          </p>
        </div>
      </Layout>
    )
  }
}
